// Re-export default export from generated file.
import result from './lib/generated/system-api.generated';
export const possibleTypes = result.possibleTypes;

// Export all types from generated output.
export * from './lib/generated/system-api.generated';
export * from './lib/system-api-core.module';
export * from './lib/provide-system-api-core-setup';
export * from './lib/services';
export * from './lib/decoration';
export * from './lib/utils';

export * from './lib/web';
