import { Injectable } from '@angular/core';

import { ApolloCacheFactory, ApolloConnectionService, OperationLinkOverride } from '@shure/cloud/shared/apollo';
import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { SystemService } from '../api';

import result, { ListAllOperations } from './graphql/generated/cloud-sys-api';

// Due to the large response size, the DiscoveredDevicesQuery has to use HTTP.
const linkOverrides: OperationLinkOverride[] = [
	{
		operationName: ListAllOperations.Query.DiscoveredDevicesQuery,
		link: 'http'
	}
];

@Injectable({
	providedIn: 'root'
})
export class SysApiConnectionService extends ApolloConnectionService {
	constructor(
		logger: ILogger,
		systemService: SystemService,
		apolloCacheFactory: ApolloCacheFactory,
		oktaIntfService: OktaInterfaceService
	) {
		super(
			logger,
			'sysApi',
			result.possibleTypes,
			4,
			systemService.getSysApiConnectInfo(),
			apolloCacheFactory,
			oktaIntfService,
			linkOverrides
		);
		monitorLoginState(oktaIntfService, {
			onLogOut: apolloCacheFactory.resetCache.bind(this)
		});
	}
}
