import { Subscriber, Subscription, timer } from 'rxjs';

/**
 * This class is used to track an observer object and provide
 * a function to emit completion of the observer upon receipt
 * of an event or a timeout has occured.
 **
 * The impetus for this class was to help throttle the rate of
 * suscription messages to the BE; since subscriptions don't
 * complete, we use the receipt of a subscription event to indicate
 * the subscription is active.  But, we needed a timeout component
 * to this to account for the case when we don't receive an event for
 * a long time.
 */
export class EventObserver {
	private timerSubscription?: Subscription;
	constructor(
		private observer: Subscriber<boolean>,
		waitTime: number
	) {
		if (observer.closed) return;
		this.timerSubscription = timer(waitTime).subscribe(() => {
			if (!this.observer.closed) {
				this.observer.complete();
			}
		});
	}
	public eventReceived(): void {
		if (!this.observer.closed) {
			this.timerSubscription?.unsubscribe();
			this.observer.complete();
		}
	}
}
