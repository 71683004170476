import { Inject, Injectable } from '@angular/core';
import {
	ApolloCache,
	defaultDataIdFromObject,
	InMemoryCache,
	NormalizedCacheObject,
	PossibleTypesMap
} from '@apollo/client';
import { mergeDeepArray } from '@apollo/client/utilities';

import { ApolloCacheManager } from './apollo-cache-manager.service';
import { ApolloTypePolicyProvider, APOLLO_TYPE_POLICY_PROVIDERS } from './apollo-type-policy.provider';

@Injectable({
	providedIn: 'root'
})
export class ApolloCacheFactory {
	private _cache: InMemoryCache | undefined;

	constructor(
		@Inject(APOLLO_TYPE_POLICY_PROVIDERS) private readonly typePolicyProviders: ApolloTypePolicyProvider[],
		private readonly apolloCacheManager: ApolloCacheManager
	) {}

	public createCache(name: string, possibleTypes: PossibleTypesMap): ApolloCache<NormalizedCacheObject> {
		const typePolicies = mergeDeepArray(this.typePolicyProviders.map((x) => x.typePolicies()));
		const typenameMap = mergeDeepArray(this.typePolicyProviders.map((x) => x.typenameMap()));
		this._cache = new InMemoryCache({
			possibleTypes,
			// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
			dataIdFromObject(responseObject) {
				const typeNameMapper = responseObject.__typename;
				if (typeNameMapper !== undefined && typeNameMapper in typenameMap) {
					return typenameMap[typeNameMapper](responseObject);
				}
				return defaultDataIdFromObject(responseObject);
			},
			typePolicies,
			// fyi: https://github.com/apollographql/apollo-client/issues/10739
			resultCacheMaxSize: Math.pow(2, 32)
		});
		this.apolloCacheManager.addCache(name, this._cache);

		this.resetCache();
		return this._cache;
	}

	public get cache(): InMemoryCache | undefined {
		return this._cache;
	}

	public resetCache(): void {
		this._cache?.reset();
	}
}
