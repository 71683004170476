import { Injectable } from '@angular/core';
import { asyncScheduler } from 'rxjs';

import { ApolloCacheFactory, ApolloCacheManager } from '@shure/cloud/shared/apollo';

import {
	InventoryDeviceByIdQueryDocument,
	InventoryDeviceByIdQueryVariables,
	InventoryDeviceFragment
} from './graphql/generated/cloud-sys-api';

@Injectable({ providedIn: 'root' })
export class SysApiDeviceInventoryApolloCache {
	constructor(
		private readonly apolloCacheManager: ApolloCacheManager,
		private readonly apolloCache: ApolloCacheFactory
	) {}

	public seedEntry(device: InventoryDeviceFragment): void {
		const variables: InventoryDeviceByIdQueryVariables = {
			nodeId: device.id,
			requestTags: true,
			requestLicenseV3: true
		};
		this.apolloCache.cache?.writeQuery({
			query: InventoryDeviceByIdQueryDocument,
			data: { node: device },
			variables,
			broadcast: false, // significant perf cost if this is TRUE.
			overwrite: true
		});
	}

	public removeEntry(id: string): void {
		// Schedule the cache removal for a short amount of time in the future
		// This allows any watchQueries to get removed w/out reporting errors.
		asyncScheduler.schedule(
			() => this.apolloCacheManager.getApolloCache('sysApi')?.evict({ id: `Device:${id}`, broadcast: false }),
			1000
		);
	}
}
