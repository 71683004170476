// Used by sys-api for DI
import 'reflect-metadata';
// Logging has to be setup before attempting to setup application link
// eslint-disable-next-line eslint-plugin-import/order
import { configureLogger } from '@shure/api-logger';
configureLogger({ level: 'Informational', logToConsole: true });

import { EnvironmentProviders, makeEnvironmentProviders, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { APOLLO_NAMED_OPTIONS, NamedOptions, ApolloModule } from 'apollo-angular';

import { APOLLO_TYPE_POLICY_PROVIDERS } from '../services';
import { SysApiTypePolicyProvider } from '../type-policy/sys-api.type-policy-provider';

import { SysApiBrowserConnectionService, SYS_API_NAME } from './sys-api-browser-connection.service';

function initializeSysApiApplicationLink(address: DeviceAddress, device: DeviceBundle) {
	return (connection: SysApiBrowserConnectionService) => {
		return async (): Promise<void> => {
			const { createApplicationLink } = await import('@shure/component-ui-api');
			const { link } = await createApplicationLink({ self: address, devices: [device] });
			connection.setApplicationLink(<never>link);
		};
	};
}

export type DeviceAddress = { host: string; port: number };
export type DeviceBundle = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register: (register: any) => void;
	descriptions: unknown[];
};

/**
 * Setup all providers required to run SystemApi targeting a specific device in the browser.
 */
export function provideSysApiBrowserSetup(address: DeviceAddress, device: DeviceBundle): EnvironmentProviders {
	return makeEnvironmentProviders([
		importProvidersFrom(ApolloModule),
		SysApiBrowserConnectionService,
		{
			provide: APOLLO_TYPE_POLICY_PROVIDERS,
			multi: true,
			useClass: SysApiTypePolicyProvider
		},
		// APP_INITIALIZER will be run before anything else thus initializing the
		// required apollo link before APOLLO_NAMED_OPTIONS are provided
		{
			provide: APP_INITIALIZER,
			useFactory: initializeSysApiApplicationLink(address, device),
			deps: [SysApiBrowserConnectionService],
			multi: true
		},
		{
			provide: APOLLO_NAMED_OPTIONS,
			useFactory: (connection: SysApiBrowserConnectionService): NamedOptions => ({
				[SYS_API_NAME]: connection.toApolloClientOptions()
			}),
			deps: [SysApiBrowserConnectionService]
		}
	]);
}
