import { ApolloError } from '@apollo/client';

export const nonRetryableErrorSubStrings = ['Device not found'];

// check the apollo error structure to determine if the errori.message field
// or one of the errors in the array of GraphQLErrors is is one of the
// substrings specified in the errorSubstrings array of strings.
// returns truee at first occurance of finding a match.
// returns fals if none of the errors match.
export function apolloErrorContains(apolloError: ApolloError, errorSubstrings: string[]): boolean {
	if (!apolloError) return true;
	const gqlErrors: string[] = [];
	gqlErrors.push(apolloError.message);
	if (apolloError.graphQLErrors !== undefined) {
		gqlErrors.push(...apolloError.graphQLErrors.map((error) => error.message));
	}
	for (const gqlError of gqlErrors) {
		for (const nrError of errorSubstrings) {
			if (gqlError.includes(nrError)) {
				return true;
			}
		}
	}
	return false;
}
