import { Injectable } from '@angular/core';
import { ApolloClientOptions, from, NormalizedCacheObject } from '@apollo/client';

import { ApplicationLink } from '@shure/application-apollo-link-web';

import { default as sysApi } from '../generated/system-api.generated';
import { ApolloCacheFactory } from '../services';

export const SYS_API_NAME = 'sysApi';

@Injectable()
export class SysApiBrowserConnectionService {
	/**
	 * Link to use for comms with sys api. Is set via `APP_INITIALIZER`
	 * in `provideSysApiBrowserSetup` due to async nature of setting
	 * bootstrapping the sys api connection.
	 */
	private sysApiLink: ApplicationLink | undefined = undefined;

	constructor(private readonly apolloCacheFactory: ApolloCacheFactory) {}

	/**
	 * Set `ApplicationLink` to use for comms. with sys api.
	 *
	 * **Should not be called directly - `provideSysApiBrowserSetup` will set it up!**
	 */
	public setApplicationLink(link: ApplicationLink): void {
		if (this.sysApiLink) {
			throw new Error('sysApiLink already set! Should only be set once via provideSysApiBrowserSetup');
		}

		this.sysApiLink = link;
	}

	/** Create apollo client options for connection  */
	public toApolloClientOptions(): ApolloClientOptions<NormalizedCacheObject> {
		if (!this.sysApiLink) {
			throw new Error(
				'sysApiLink is missing. Run provideSysApiBrowserSetup to setup all providers required to run sys api in the browser'
			);
		}

		return {
			cache: this.apolloCacheFactory.createCache(SYS_API_NAME, sysApi.possibleTypes),
			link: from([this.sysApiLink])
		};
	}
}
