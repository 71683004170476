import { LicenseState } from '@shure/cloud/shared/models/devices';

import {
	LicenseState as SysApiLicenseState,
	LicenseStateV3 as SysApiLicenseStateV3
} from '../graphql/generated/cloud-sys-api';

export function mapLicenseStateFromSysApi(state: SysApiLicenseState | SysApiLicenseStateV3): LicenseState {
	return <LicenseState>state;
}
